* {
  margin: 0;
  padding: 0;
  font: inherit;
  color: inherit;
}

*,
:after,
:before {
  box-sizing: border-box;
}

:root {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  overflow-wrap: break-word;
  -moz-tab-size: 4;
  tab-size: 4;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  line-height: inherit;
  word-break: keep-all;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

button {
  background-color: transparent;
  cursor: pointer;
}

button,
input {
  -webkit-border-radius: 0;
  border-radius: 0;
  border: 0;
  outline: 0;
}

button,
textarea,
input:disabled {
  background-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

textarea {
  display: block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
